<template>
  <div class="top">
    <slot name="header" />
    <main>
      <slot name="contents" />
    </main>
    <slot name="footer" />
  </div>
</template>

<script>
export default {
  name: 'TopLayout',
}
</script>

<style scoped lang="scss">

::v-deep {
  section .black_red {
    font-size: 68px;
    font-weight: 700;
    text-align: center;
    display: block;
    .small {
      font-size: 24px;
    }
  }
  section .black_red .red {
    color: #fe3340;
  }
  section .under {
    font-size: 20px;
    line-height: 32px;
    text-align: center;
  }
  .cv {
    width: 660px;
    margin: 0 auto;
  }
  .cv a {
    transition: ease 0.3s;
  }
  .cv a:hover {
    opacity: 0.8;
  }
  h2.red {
    font-size: 52px;
    font-family: 'Roboto Condensed', sans-serif;
    text-align: center;
    color: #fe3340;
    margin-bottom: 70px;
  }
  h2.red span {
    font-size: 26px;
    display: block;
    margin-top: 10px;
  }
  .marker {
    background: linear-gradient(transparent 70%, #fcfe36 30%);
  }
  .marker2 {
    background: linear-gradient(transparent 6%,#fcfe36 6%, #fcfe36 90%, transparent 90%, transparent 100% );
  }
  @media only screen and (max-width: 768px) {
    section .black_red {
      font-size: 27px;
      margin-bottom: 10px;
    }
    section .under {
      display: block;
      font-size: 14px;
      line-height: 24px;
      padding: 0 15px;
      margin: 0 auto;
    }
    .cv {
      width: 100%;
      padding: 0 20px;
      box-sizing: border-box;
    }
    h2.red {
      font-size: 32px;
      margin-bottom: 40px;
    }
    h2.red span {
      font-size: 15px;
      margin-top: 5px;
    }
  }
  figure.movie {
    &.up {
      a {
        &::after {
          bottom: 14%;
        }
      }
    }
    a {
      position: relative;
      display: block;
      width: 100%;
      height: 100%;
      &::after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-image: url("/top2020/img/icon_movie.svg");
        background-position: center;
        background-size: 30% 30%;
        background-repeat: no-repeat;
      }
    }
  }
  .movie-text {
    font-size: 16px;
    line-height: 24px;
    text-align: right;
  }
  @media only screen and (max-width: 768px) {
    .movie-text {
      font-size: 14px;
    }
  }
}
</style>
