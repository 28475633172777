var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"shopping-guide"},[_vm._m(0),_c('div',{staticClass:"container01 inner01 flexb"},[_c('div',{staticClass:"left"},[_c('h3',[_vm._v("【お支払い方法について】")]),_c('p',[_vm._v("クレジットカード決済、コンビニ決済、代金引換がご利用いただけます。")]),_c('h4',[_vm._v("●クレジットカード決済")]),_c('p',[_vm._v("JCB、VISA、Master Card、Diners Club、American Expressがご利用いただけます。")]),_c('figure',{staticClass:"f1"},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:('/top2020/img/tc16_01.webp'),expression:"'/top2020/img/tc16_01.webp'"}],attrs:{"alt":""}})]),_vm._m(1),_c('h4',[_vm._v("●コンビニ決済")]),_vm._m(2),_c('h4',[_vm._v("●代金引換")]),_c('p',[_vm._v("宅急便コレクトがご利用いただけます。")]),_c('figure',{staticClass:"f5"},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:('/top2020/img/tc16_05.webp'),expression:"'/top2020/img/tc16_05.webp'"}],attrs:{"alt":""}})]),_c('p',[_vm._v("※代引き手数料は無料です。")])]),_c('div',{staticClass:"right"},[_c('h3',[_vm._v("【配送について】")]),_vm._m(3),_c('h3',[_vm._v("【58日間全額返金保証について】")]),_vm._m(4),_c('figure',{staticClass:"f5 large-margin"},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:('/top2020/img/tc16_06.webp'),expression:"'/top2020/img/tc16_06.webp'"}],attrs:{"alt":""}})]),_c('h3',[_vm._v("JJ ENGLISHエクササイズはフィリピン・セブ島のボランティア団体の活動を支援しています。")]),_vm._m(5)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('h2',{staticClass:"red"},[_vm._v("INFORMATION"),_c('span',[_vm._v("お買い物ガイド")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v("※分割払い、リボ払いの際の分割手数料はお客様の"),_c('br'),_vm._v("ご負担となります。")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v(" 全国のローソン、ファミリーマート、セイコーマート、ミニストップでお支払いができます。"),_c('br'),_c('br'),_vm._v(" ◯ご注文の受付後に配信される「コンビニでのお支払方法について」のメールおよび、「コンビニお支払い受付番号」の通知メール内容に沿ってお支払いいただくコンビニをお選びいただき、お支払期限内(8日以内)にお支払いをお願いいたします。"),_c('br'),_vm._v(" ◯お支払い完了の確認がとれ次第、商品の発送手続きをいたします。"),_c('br'),_vm._v(" ◯決済額は決済手数料（253円）を含んだ金額になります。"),_c('br'),_vm._v(" ◯お支払期限内にお支払の確認がとれない場合は、ご注文をキャンセルとさせていただきます。"),_c('br'),_vm._v(" ◯コンビニ店頭でのお支払い方法は現金のみとなります。"),_c('br'),_vm._v(" ◯返品などにより返金が必要となった場合は銀行振込での返金となります。（コンビニ店頭での返金はおこなえません） ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v("・送料無料にてお届けいたします。"),_c('br'),_vm._v("・配送はヤマト運輸（宅急便）でお届けいたします。"),_c('br'),_vm._v("・14時までのご注文で当日発送いたします。"),_c('br'),_vm._v("・定休日：土日祝・年末年始。")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v(" 20Lesson以上、当教材推奨のエクササイズを実践されても学習の成果がまったく得られないと感じられた場合、58日間の返金期間を設けております。"),_c('br'),_vm._v(" 返金希望の方は info@jjenglish.co.jpまで、件名に「返金希望」と明記の上、メール本文に下記の内容を記載しお送りください。"),_c('br'),_vm._v(" ・お名前"),_c('br'),_vm._v(" ・返品理由（未開封や実践の形跡が認められない場合、返金をお断りする場合があります）"),_c('br'),_vm._v(" ・メールアドレス"),_c('br'),_vm._v(" ・注文番号"),_c('br'),_vm._v(" ・振込先の金融機関名・支店名・口座種別・口座番号・名義"),_c('br'),_vm._v(" ※弊社にて教材の返品を確認後、15営業日以内にお客様の口座に全額返金いたします。"),_c('br'),_vm._v(" ※購入時のセットが揃っている方に限ります。返品時の送料・振込手数料はお客様のご負担となります。"),_c('br'),_c('span',{staticClass:"blue"},[_vm._v("※領収書を発行してのご購入の場合は返金できません。ご注意ください。")]),_c('br'),_vm._v(" ※58日間の保証はご注文日からの起算となります。 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v("NPO法人DAREDEMO HERO（誰でもヒーロー）は、フィリピン・セブ島で活動するボランティア団体です。貧困ゆえに学習ができない地元の子どもたちを支援し、将来、フィリピンの貧困問題を解決するリーダーを育てることを目的としています。JJイングリッシュ社では、教材の研究・制作過程でご縁のあったこの団体に、利益の一部を寄付するなどの支援を行っています。"),_c('br'),_vm._v("DAREDEMO HERO公式ウエブサイト "),_c('a',{staticClass:"link",attrs:{"href":"https://daredemohero.com","target":"_blank"}},[_vm._v("https://daredemohero.com")])])
}]

export { render, staticRenderFns }