var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"lazyload",attrs:{"id":"tc2"}},[_vm._m(0),_vm._m(1),_c('p'),_vm._m(2),_c('div',{staticClass:"container01 inner02 flexb"},[_c('dl',{staticClass:"flex"},[_vm._m(3),_c('dd',[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:('/top2020/img/tc2_06_2.webp'),expression:"'/top2020/img/tc2_06_2.webp'"}],attrs:{"alt":""}})])]),_c('dl',{staticClass:"flex"},[_vm._m(4),_c('dd',[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:('/top2020/img/tc2_02_2.webp'),expression:"'/top2020/img/tc2_02_2.webp'"}],attrs:{"alt":""}})])]),_c('dl',{staticClass:"flex"},[_vm._m(5),_c('dd',[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:('/top2020/img/tc2_03.webp'),expression:"'/top2020/img/tc2_03.webp'"}],attrs:{"alt":""}})])]),_c('dl',{staticClass:"flex"},[_vm._m(6),_c('dd',[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:('/top2020/img/tc2_04_2.webp'),expression:"'/top2020/img/tc2_04_2.webp'"}],attrs:{"alt":""}})])]),_c('dl',{staticClass:"flex"},[_vm._m(7),_c('dd',[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:('/top2020/img/tc2_08.webp'),expression:"'/top2020/img/tc2_08.webp'"}],attrs:{"alt":""}})])]),_c('dl',{staticClass:"flex"},[_vm._m(8),_c('dd',[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:('/top2020/img/tc2_01_2.webp'),expression:"'/top2020/img/tc2_01_2.webp'"}],attrs:{"alt":""}})])]),_c('dl',{staticClass:"flex"})]),_c('div',{staticClass:"container02 inner01 flexb"},[_c('figure',[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:('/top2020/img/tc2_07.webp'),expression:"'/top2020/img/tc2_07.webp'"}],attrs:{"alt":""}})]),_vm._m(9)]),_vm._m(10)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('h2',[_c('span',{staticClass:"title"},[_c('span',{staticClass:"large"},[_vm._v("この教材で身につく英語は…")])]),_c('br'),_vm._v(" 国際基準「CEFR」の A1/A2～B1レベルに相当"),_c('br'),_c('span',{staticClass:"under"},[_vm._v("「CEFR（セファール）」とは、外国語習得の段階を示す国際的な共通基準です。")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h2',{staticClass:"white"},[_c('span',{staticClass:"small"},[_vm._v("その英語は、話せない人や今のあなたからすれば")]),_c('br'),_vm._v(" 「あの人、英語が話せるんだ」"),_c('br'),_c('span',{staticClass:"small"},[_vm._v("という憧れの的になるレベルです。")]),_c('br'),_c('span',{staticClass:"tiny"},[_vm._v("（話せない人からは「ペラペラですね」と言われるかもしれません）")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h3',[_vm._v(" ネイティブ並みの英語でなくても"),_c('br'),_c('span',{staticClass:"large"},[_vm._v("話せるヨロコビは無限大！")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('dt',[_c('span',[_vm._v("世界が住まいに。"),_c('br'),_vm._v(" 大好きな国や土地で"),_c('br'),_vm._v(" 最高の人生を！")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('dt',[_c('span',[_vm._v("滞在中の外国人と"),_c('br'),_vm._v(" いきなり交流！"),_c('br'),_vm._v(" 盛り上がれます")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('dt',[_c('span',[_vm._v("部署を異動して"),_c('br'),_vm._v(" やりがいも"),_c('br'),_vm._v(" 給料もアップ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('dt',[_c('span',[_vm._v("海外旅行で"),_c('br'),_vm._v(" 不自由がなくなり"),_c('br'),_vm._v(" 10倍楽しめる")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('dt',[_c('span',[_vm._v("遠い異国の人が"),_c('br'),_vm._v(" 恋人になって"),_c('br'),_vm._v(" しまうかも！")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('dt',[_c('span',[_vm._v("英語で日本を案内！"),_c('br'),_vm._v(" 困った外国人を"),_c('br'),_vm._v(" 助けてあげられる")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"txt"},[_c('p',[_vm._v(" 国際基準CEFRの初級A１／A２から中級者のB１レベルの英語とは、旅行先はもちろん、ほとんどの日常場面で、どんな国の人を相手にしても、"),_c('br'),_c('br'),_vm._v(" ○自分の考えや気持ちを伝えられる。"),_c('br'),_vm._v(" ○"),_c('span',{staticClass:"marker2"},[_vm._v("物事について簡潔な説明ができる。")]),_c('br'),_vm._v(" （さらに必要な単語を覚えると）"),_c('br'),_vm._v(" ○"),_c('span',{staticClass:"marker2"},[_vm._v("趣味・文化・社会的な話題について会話ができる。")]),_c('br'),_vm._v(" ○"),_c('span',{staticClass:"marker2"},[_vm._v("仕事の現場でもしっかりコミュニケーションがとれる。")]),_c('br'),_c('br'),_vm._v(" というレベルです。 ")]),_c('dl',[_c('dt',[_vm._v("CEFR A1/A2～B1レベル")]),_c('dd',[_vm._v(" ＝英検2級～準1級に相当"),_c('br'),_vm._v(" ＝TOEIC 550～785点に相当"),_c('br'),_c('span',{staticClass:"kome"},[_vm._v("※スピーキング、リスニングでの対照です。")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container02 inner01 center"},[_c('h3',[_c('span',{staticClass:"border"},[_vm._v("驚き！ こんな会話が英語で言えるようになる！！")])]),_c('p',[_c('span',{staticClass:"bold"},[_vm._v("例")]),_vm._v("：「絵画や文学のテーマにもなってきた富士山は、日本人の心のよりどころなんだ」"),_c('br'),_c('span',{staticClass:"en"},[_vm._v("Mt.Fuji has been the subject of art and literature. It’s a spiritual home for the Japanese people.")]),_c('br'),_c('span',{staticClass:"bold"},[_vm._v("例")]),_vm._v("：「とんでもない！ ラーメンは、１つの丼で肉と野菜とスープと麺を楽しめるフルコース料理なのよ」"),_c('br'),_c('span',{staticClass:"en"},[_vm._v("No, it’s not at all. With ramen you get meat, vegetables, soup and noodles all in the one bowl. They say that it’s a full-course meal.")]),_c('br'),_c('span',{staticClass:"bold"},[_vm._v("例")]),_vm._v("：「外国人に優しい、それがドン・キホーテの特徴なのよ！」"),_c('br'),_c('span',{staticClass:"en"},[_vm._v("It is kind to foreigners; that’s Don Quijote!")]),_c('br'),_c('span',{staticClass:"bold"},[_vm._v("（JJ ENGLISHエクササイズのテキストより）")])]),_c('h3',{staticClass:"top-margin"},[_vm._v(" いま、「少しむずかしいかも…」と思ったあなたでも、"),_c('br'),_vm._v(" 適切なエクササイズを続ければ、口から出る日が必ず来ます。"),_c('br'),_c('br'),_c('br'),_vm._v(" そして……、"),_c('br'),_c('span',{staticClass:"large"},[_vm._v(" 「ネイティブ並みの英語」は、"),_c('br'),_vm._v(" このレベルの先にだけあるのです。 ")])])])
}]

export { render, staticRenderFns }