var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"lazyload",attrs:{"id":"tc3"}},[_vm._m(0),_vm._m(1),_vm._m(2),_c('div',{staticClass:"container inner01"},[_c('div',{staticClass:"box flexb b1"},[_c('div',{staticClass:"img"},[_c('figure',[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:('/top2020/img/yasukochi2.webp'),expression:"'/top2020/img/yasukochi2.webp'"}],attrs:{"alt":""}})])]),_vm._m(3)]),_c('div',{staticClass:"box flexb b2"},[_c('div',{staticClass:"img"},[_c('figure',[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:('/top2020/img/yokoyama2.webp'),expression:"'/top2020/img/yokoyama2.webp'"}],attrs:{"alt":""}})])]),_vm._m(4)]),_c('div',{staticClass:"box flexb b3"},[_c('div',{staticClass:"img"},[_c('figure',[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:('/top2020/img/mizuno2.webp'),expression:"'/top2020/img/mizuno2.webp'"}],attrs:{"alt":""}})])]),_vm._m(5)]),_c('div',{staticClass:"box flexb b4"},[_c('div',{staticClass:"img"},[_c('figure',[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:('/top2020/img/yokote2.webp'),expression:"'/top2020/img/yokote2.webp'"}],attrs:{"alt":""}})])]),_vm._m(6)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('h2',{staticClass:"red"},[_vm._v("INSTRUCTORS"),_c('span',[_vm._v("監修＆コーチ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('strong',{staticClass:"black_red"},[_vm._v("私たちも"),_c('span',{staticClass:"red"},[_vm._v("同じ道")]),_vm._v("を"),_c('span',{staticClass:"red"},[_vm._v("進み")]),_vm._v("ました！")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"under"},[_vm._v(" だからレッスンもメソッドも圧倒的な信頼度"),_c('br'),_vm._v(" 全員“純ジャパ”で、日本で英会話を習得した先生ばかりです ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"txt"},[_c('p',{staticClass:"p1"},[_vm._v("”今の自分の英語”を使いながら上達しよう！")]),_c('p',{staticClass:"p2"},[_vm._v("東進ハイスクール講師"),_c('br',{staticClass:"pc_only"}),_vm._v("受験英語の神")]),_c('h3',[_vm._v("安河内 哲也")]),_c('p',{staticClass:"p3"},[_vm._v("子どもから大人まで、難しい用語を使わずに英語を楽しく教えることで人気。予備校や中学･高校での講演の他、大学での特別講義や企業の研修などでも講師を務めている。東進ハイスクール・東進ビジネススクール英語科講師。上智大学外国語学部英語学科卒。全国通訳案内士。"),_c('span',{staticClass:"marker2"},[_vm._v("著書の累計発行部数は400万部以上。NHKテキスト『もっと伝わる! 即レス英会話』に連載中。")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"txt"},[_c('p',{staticClass:"p1"},[_vm._v("僕にもできました。アナタにもできます！")]),_c('p',{staticClass:"p2"},[_vm._v("画期的メソッド「パワー音読🄬」を開発")]),_c('h3',[_vm._v("横山 カズ")]),_c('p',{staticClass:"p3"},[_vm._v("独学で英語を習得し同時通訳者となる。医療・環境・ITなど多彩な分野の国際会議、記者会見で同時通訳を担当。翻訳家としても活躍中。従来の音読を発展・進化させた「パワー音読」を開発。関西外国語大学外国語学部卒。"),_c('span',{staticClass:"marker2"},[_vm._v("スタディサプリ講師。NHK『ラジオ英会話タイムトライアル』連載中。")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"txt"},[_c('p',{staticClass:"p1"},[_vm._v("通じるだけを目標にせず、より美しく。")]),_c('p',{staticClass:"p2"},[_vm._v("オックスフォード仕込みの英語を伝授")]),_c('h3',[_vm._v("水野 稚")]),_c('p',{staticClass:"p3"},[_vm._v("イギリス英語にも堪能。世界とわたり合える日本人を増やしたいとの思いから、英語教育のエキスパートへ。Primus Edge代表取締役/プリムスアカデミー代表。"),_c('span',{staticClass:"marker2"},[_vm._v("スタディサプリ講師。")]),_vm._v("東京大学大学院博士課程在籍中にオックスフォード大学大学院に留学、修士号を取得。")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"txt"},[_c('p',{staticClass:"p1"},[_vm._v("シンプルな英語。それも”おもてなし”です。")]),_c('p',{staticClass:"p2"},[_vm._v("元国際線CAの経験から接客英語を指導")]),_c('h3',[_vm._v("横手 尚子")]),_c('p',{staticClass:"p3"},[_vm._v("元日本航空国際線客室乗務員として"),_c('span',{staticClass:"marker2"},[_vm._v("英語での接客に経験豊富。")]),_vm._v("後進の指導に当たるほか、接客・マナー講師として活躍中。明るい授業が人気で、国内外の学生、社会人に接客英語と発音などを指導。学習院大学卒。武蔵野学院大学准教授。英語発音指導士。")])])
}]

export { render, staticRenderFns }