import { render, staticRenderFns } from "./Gap.vue?vue&type=template&id=f7f839ac&scoped=true"
import script from "./Gap.vue?vue&type=script&lang=js"
export * from "./Gap.vue?vue&type=script&lang=js"
import style0 from "./Gap.vue?vue&type=style&index=0&id=f7f839ac&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f7f839ac",
  null
  
)

export default component.exports